import * as React from "react"
import { graphql, HeadFC, useStaticQuery } from "gatsby"
import Header from "../components/header"
import ButtonLink from "../components/button-link"
import Footer from "../components/footer"
import { FiMail, FiPhone } from "react-icons/fi"
import Hero from "../components/hero"
import CasesGrid from "../components/cases-grid"
import ImageSection from "../components/image-section"
import Seo from "../components/seo"
import ContactLinks from "../components/contact-links"

const IndexPage = () => {
  // Fetch all cases at build time
  const { allStrapiCase, allStrapiTestimonial } = useStaticQuery(graphql`
    query {
      allStrapiCase {
        nodes {
          ...CaseCard
        }
      }
      allStrapiTestimonial(limit: 2) {
        nodes {
          author
          company
          body
        }
      }
    }
  `)

  return (
    <>
      {/* SEO */}
      <Seo seo={{ metaTitle: 'Website laten maken' }}/>

      {/* Navigation */}
      <Header/>

      {/* Hero */}
      <Hero data={{
        image: 'https://images.unsplash.com/photo-1557425631-f132f06f4aa1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1137&q=80'
      }}>
        <h1 className="col-span-7 text-white">Wij bouwen een <br /> <span className="text-brand-green">winstgevende</span> website <br /> voor jouw bedrijf</h1>
        <ul className="col-span-9 list-square flex flex-col gap-2">
          <li>SEO geoptimaliseerd</li>
          <li>Conversie gericht</li>
          <li>Snelle laadtijden</li>
        </ul>    
        <div className="col-span-9 flex">
          <ButtonLink to="/contact" color="green-white" className="inline">Neem contact op</ButtonLink>
        </div>  
      </Hero>

      {/* Section */}
      <section className="container mx-auto flex flex-col gap-y-8 md:grid grid-cols-12 my-16 px-6 md:px-0">
        <h1 className="col-start-2 col-span-5">Meer <span className="text-brand-orange">resultaat</span> met je website</h1>
        <div className="flex flex-col gap-8 items-start col-start-7 col-span-5">
          <p>
            Met een op maat gebouwde en geoptimaliseerde
            website ontvang je meer bezoekers. Je online
            zichtbaarheid neemt toe, je genereert meer leads en
            bouwt een proffesionale uitstraling op richting de klant.
            Wij zijn gespecialiseerd in het ontwerpen en bouwen van
            een presterende website. Samen maken we jouw
            toekomst een online succes.
          </p>          
          <ButtonLink to="/contact">Neem contact op</ButtonLink>
        </div>
      </section>

      {/* Section */}
      <ImageSection image="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1415&q=80">
        <h1>Waar we goed in zijn</h1>
        <ul className="list-square flex flex-col gap-4 text-slate-500">
          <li>
            <b>Design dat bezoekers aanspreekt</b> <br />
            Een modern ogende website waar gebruikers vinden wat ze zoeken.
            We denken vanuit de bezoeker om de gebruikerservaring te optimaliseren.
          </li>
          <li>
            <b>Razendsnelle laadtijden</b> <br />
            Hoe langer de laadtijd, hoe meer bezoekers wegklicken.
            Daarom focussen wij op het verkorten van laatijden zodat jouw website optimaal presteert.
          </li>
          <li>
            <b>Vindbare websites</b> <br />
            Om gevonden te worden in zoekmachines is veel nodig.
            Wij doen het werk achter de schermen waardoor zoekmachines jouw website hoger plaatsen.
          </li>
        </ul>
      </ImageSection>

      {/* Portfolio */}
      <section className="container mx-auto my-16 px-6 md:px-0">
        <div className="flex justify-between items-end mb-8">
          <h1>Werk waar we trots op zijn</h1>
          <ButtonLink to="/cases">Bekijk alle cases</ButtonLink>
        </div>
        <CasesGrid cases={allStrapiCase.nodes}/>
      </section>

      {/* Section */}
      <ImageSection reverse image="https://cms.deltafuturum.nl/uploads/medium_profiel_a6848accff.jpg">
        <h1>Jouw website laten maken?</h1>
        <p>
          Wil jij ook jouw digitale positie versterken door een presterende website te laten maken? Neem dan vrijblijvend contact op. We denken graag met je mee en kijken wat we voor jouw bedrijf kunnen betekenen.
        </p>
        <ButtonLink to="/contact">Neem contact op</ButtonLink>
        <ContactLinks whatsapp={false} />
      </ImageSection>

      {/* Testimonials */}
      <section className="container mx-auto my-16 px-6 md:px-0 flex flex-col md:grid grid-cols-12 gap-y-8 gap-x-6">
        <h1 className="col-span-12">Zij gingen je voor</h1>
        <p className="col-span-5">
          Benieuwd hoe andere opdrachtgevers terugkijken
          op onze samenwerking? Laat je overtuigen
          door hun referenties.
        </p>
        <div className="col-span-12 grid md:grid-cols-2 gap-6">
          {allStrapiTestimonial.nodes.map((testimonial: any, index: number) => (
            <div key={index} className="col-span-6 p-12 bg-brand-blue-light text-slate-500">
              <p className="mb-4">
                {testimonial.body}
              </p>
              <p>
                <b>{testimonial.author}</b> <br />
                {testimonial.company}
              </p>
            </div>
          ))}
        </div>
        <div className="col-span-12 flex justify-center">
          <ButtonLink to="/contact">Neem contact op</ButtonLink>
        </div>
      </section>

      <Footer/>
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Website laten maken | Delta Futurum</title>
